import * as React from 'react'
import { useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import { ComMain } from '@components/index'
import { FeedInFunc } from '@func/index'
import Icon from '@svgs/logo_odawara_icon.svg'

const DesignPage = () => {

  useEffect(() => {
    FeedInFunc();
  }, []);

  return (
    <main className='mainDesign'>
      {/* CONTENTS */}

      <ComMain
        ttl="DESIGN"
        subttl="デザイン"
        caption="エントランス完成予想CG"
      >
        <StaticImage
          src="../assets/images/design/main.jpg"
          alt=""
          className="_pc"
        />
        <StaticImage
          src="../assets/images/design/main_sp.jpg"
          alt=""
          className="_sp"
        />
      </ComMain>

      <section className='sec-concept'>
        <div className="c-content">

          <div className="concept-wrap">
            <div className="flex-box">
              <div className="txt-box comFeed">
                <div className="design-txt-box">
                  <p className="ttl"><span><Icon /></span>DESIGN CONCEPT</p>
                  <p className="lead">城下町の風情を感じる<br />小田原の邸宅。</p>
                  <p className="txt">歴史と伝統ある城下町の風情を今に残す現地界隈。<br />「銀座通り交差点」に立地する「ル・サンク小田原栄町」は城下町の風趣を素材にしてその魅力を磨き上げ、街並に溶け込みながら地域全体とつながり、 小田原の魅力を高めていく建築として計画しています。</p>
                </div>
              </div>
              <div className="img-box comFeed">
                <div className="top">
                  <div className="img img-1">
                    <StaticImage
                      src="../assets/images/design/i_1.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="btm">
                  <div className="img img-2">
                    <StaticImage
                      src="../assets/images/design/i_2.jpg"
                      alt=""
                    />
                  </div>
                  <div className="img img-3">
                    <StaticImage
                      src="../assets/images/design/i_3.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="note">
                  <p>1.小田原城（約390m／徒歩5分）  2.ミナカ小田原（約340m／徒歩5分） 3.image photo</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>


      <section className='sec-fasade'>
        <div className="c-content">

          <div className="mainimg-wrap">
            <div className="mainimg-box comFeed">
              <div className="img">
                <StaticImage
                  src="../assets/images/design/i_4.png"
                  alt=""
                />
              </div>
              <p className="c-cap">外観完成予想CG</p>
            </div>

            <div className="txt-box comFeed">
              <div className="design-txt-box">
                <p className="ttl"><span><Icon /></span>FACADE DESIGN</p>
                <p className="lead">全邸南向き。<br />和とモダニズムを融合した、<br />道行く人々の視線を集める<br className='_sp' />瀟洒な私邸。</p>
                <p className="txt">「ル・サンク小田原栄町」は小田原の城下町にこそふさわしい、町屋建築をイメージした重厚感あるデザインを採用。<br />出桁造りという小田原の伝統的な町屋の建築方法をエッセンスに採り入れ水平ラインを強調し、街並と連続するファサードデザインとしました。</p>
              </div>
            </div>
          </div>

          <div className="flex-wrap">
            <div className="flex-box comFeed">

              <div className="list">
                <p className="ttl">日本建築に見られる屋根の水平ライン</p>
                <div className="flex-img">
                  <div className="img">
                    <StaticImage
                      src="../assets/images/design/i_5.png"
                      alt=""
                    />
                  </div>
                  <div className="img">
                    <StaticImage
                      src="../assets/images/design/i_6.jpg"
                      alt=""
                    />
                    <p className="c-cap">image photo</p>
                  </div>
                </div>
                <p className="txt">日本家屋の庇に倣い、最上階に庇を回したデザインを採り入れました。屋根の水平ラインが強調され、和の印象を醸し出します。</p>
              </div>
              <div className="list">
                <p className="ttl">横桟が印象的な手摺のデザイン</p>
                <div className="flex-img">
                  <div className="img">
                    <StaticImage
                      src="../assets/images/design/i_7.png"
                      alt=""
                    />
                  </div>
                  <div className="img">
                    <StaticImage
                      src="../assets/images/design/i_8.jpg"
                      alt=""
                    />
                    <p className="c-cap">image photo</p>
                  </div>
                </div>
                <p className="txt">バルコニーの手摺には横方向に桟を渡しました。日本建築によく見られる手摺のデザインを模して、宿場町らしい風景を創出しました。</p>
              </div>
              <div className="list">
                <p className="ttl">水平ラインを強調する出桁（だしげた）</p>
                <div className="flex-img">
                  <div className="img">
                    <StaticImage
                      src="../assets/images/design/i_9.png"
                      alt=""
                    />
                  </div>
                  <div className="img">
                    <StaticImage
                      src="../assets/images/design/i_10.jpg"
                      alt=""
                    />
                    <p className="c-cap">image photo</p>
                  </div>
                </div>
                <p className="txt">軒を深く前面に張り出した出桁造りは伝統的な町屋の建築方法です。この特徴的な意匠を現代風にアレンジし、各住戸のバルコニー側に小庇を設けました。</p>
              </div>

              <div className="note">
                <p>外観完成予想CG</p>
              </div>

            </div>
          </div>

        </div>
      </section>


      <section className='sec-entrance'>

        <div className="main-wrap">
          <div className="main-box comFeed">
            <div className="img">
              <StaticImage
                src="../assets/images/design/i_11.jpg"
                alt=""
              />
            </div>
            <p className="c-cap">エントランス完成予想CG</p>
          </div>
        </div>

        <div className="c-content">

          <div className="txt-wrap">
            <div className="txt-box comFeed">
              <div className="design-txt-box">
                <p className="ttl"><span><Icon /></span>ENTRANCE DESIGN</p>
                <p className="lead">小田原らしさを継承する建築。</p>
                <p className="txt">エントランスは縦のルーバーを連続的にデザイン。水平方向に大胆に延びる庇は和的な屋根を現代風にアレンジしたもの。<br />カラーリングには木造建築を意識し、焦げ茶などの飴色に近い色調を採用し、落ち着きのある和モダンな佇まいを演出しました。</p>
              </div>
            </div>
          </div>

        </div>


        <div className="c-content">
          <div className="com-note-wrap">
            <p className="c-note">※掲載の環境写真は、2021年10月に撮影したものです。<br />※掲載の距離表示については地図上の概測距離を、徒歩分数表示については80m＝1分として算出し、端数を切り上げしたものです。<br />※掲載の情報は 2022年1月時点のものです。<br />※掲載の完成予想CGは、計画段階の図面を基に描いたもので、外観・外構・植栽・仕上げ等は実際とは異なる場合があります。外観形状の細部や設備機器等は表現されておりません。またタイルや各種部材の質感や色等は実際とは異なります。行政指導及び施工上の理由等のため、今後変更となる場合があります。敷地内の植栽は育成に必要な環境の下で、竣工から一定期間を経た状態のものを想定して描いており、特定の季節を表したものではありません 。建設地周辺の建物や電柱等については、白線等で表現している部分がございます。周辺環境や景観は将来に渡り保証されるものではありません。予めご了承ください。<br />※「image photo」の表記がある写真は全てイメージです。</p>
          </div>
        </div>


      </section>


      {/* CONTENTS */}
    </main>
  )
}

export default DesignPage
